import * as React from "react";

import funnel_logo from "../Assets/Images/helpdesk-task.png"; //'/../Assets/Images/project_icon.png';

export default function ItemView({ ticket }) {
  const url = `/helpdesk/ticket/${ticket.Id}`;

  return (
    <a href={url} class="block relative">
      <li key={ticket.Id} class="flex flex-row">
        <div class="select-none hover:bg-gray-50 flex flex-1 items-center p-4">
          <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
            <img
              alt="profil"
              src={funnel_logo}
              class="mx-auto object-cover rounded-md h-14 w-14"
            />
          </div>

          <div class="flex-1 pl-1">
            <div class="font-medium dark:text-white text-sm">
              <a href={url}>{ticket.Name}</a>
              <div>{ticket.CreatedAt}</div>
            </div>
            <div class="text-sm">
              <div>{ticket.Description}</div>
            </div>
          </div>

          <div class="pl-1"></div>

          <div class="pl-1"></div>
        </div>
      </li>
    </a>
  );
}
