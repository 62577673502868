import { useForm } from "react-hook-form";
import useKeywordEdit from "./Hooks/useKeywordEdit";

import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "../../components/Core/LoadingControl/LoadingControl";

export default function ASOKeywordScore({ project }) {
  const form = useForm();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { itemEdit, loading } = useKeywordEdit(formValues, project);

  return (
    <div className="m-5">
      <form onSubmit={handleSubmit(itemEdit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              New Optimization
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Keyword
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="keyword"
                    {...register("keyword", {
                      required: "Keyword is required",
                    })}
                    id="name"
                    placeholder="Keyword"
                    className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="url"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  ASO Page Url
                </label>
                <div className="mt-2">
                  <input
                    placeholder="john@company.com"
                    id="url"
                    name="url"
                    type="text"
                    {...register("url", {
                      required: "Url is required",
                    })}
                    className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6">
          <a
            href={`/project/${project.Id}/asokeywords`}
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </a>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {loading ? <Spinner /> : <>Save</>}
          </button>
        </div>
      </form>
    </div>
  );
}
