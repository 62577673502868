import * as React from "react";

import { useCallback, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import ItemView from "./Components/ItemView";
import useTicketList from "./Hooks/useTicketList";
import { EditTicket } from "./Api/HelpDeskClient";

export default function TicketListV2({ project }) {
  const location = useLocation();
  const { data, setData, loading } = useTicketList(project?.Id);
  const [appName, setAppName] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const appNames = data
    .map((row) => row["AppKey"])
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  const selectAppName = (event) => {
    setAppName(event.target.value);
  };

  const list = data.filter((item) =>
    appName === null ? true : item.AppKey === appName
  );

  const archiveTicket = async (ticket) => {
    ticket.archived = true;
    await EditTicket(ticket);

    var newData = data.filter((i) => i.id !== ticket.id);
    setData(newData);
    setSelectedTicket(null);
  };

  const openTicketDetails = (ticket) => {
    console.log("openTicketDetails", ticket);
    setSelectedTicket(ticket);
  };

  const closeTicketDetails = () => {
    setSelectedTicket(null);
  };

  return (
    <div className="m-5 max-w-xl">
      <div>
        {loading ? (
          <LoadingControl />
        ) : (
          <>
            <label
              htmlFor="names"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select an app
            </label>
            <select
              value={appName}
              onChange={selectAppName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected value="all">
                all apps
              </option>
              {appNames.map((name) => (
                <option value={name}>{name}</option>
              ))}
            </select>

            <div>Tickets - {list.length}</div>
            <div className="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
              <ul className="flex flex-col divide-y w-full">
                {list.map((ticket) => (
                  <ItemView
                    ticket={ticket}
                    key={ticket.Id}
                    onClick={(t) => openTicketDetails(t)}
                  />
                ))}
              </ul>
            </div>
          </>
        )}
      </div>

      {selectedTicket && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-2xl overflow-y-auto max-h-screen">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Ticket Details</h2>
              <button
                onClick={closeTicketDetails}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div>
              <p>
                <strong>App:</strong> {selectedTicket.app.key}-
                {selectedTicket.app.platform}
              </p>
              <p>
                <strong>User:</strong> {selectedTicket.user.name}
              </p>
              <p>
                <strong>Email:</strong> {selectedTicket.user.email}
              </p>
              <p>
                <strong>Message:</strong> {selectedTicket.message}
              </p>
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={closeTicketDetails}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Close
              </button>
              <button
                onClick={() => archiveTicket(selectedTicket)}
                className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
              >
                Archive
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
