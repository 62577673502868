import { useCallback, useState } from "react";

import Cookies from "js-cookie";
const user_key = "get_project_user";

export function GetUser() {
  const json = Cookies.get(user_key);
  if (!json) return null;
  var currentUser = JSON.parse(json);
  return currentUser;
}

export function IsInGroup(groupName) {
  const json = Cookies.get(user_key);
  if (!json) return null;
  var currentUser = JSON.parse(json);

  var goups = currentUser.Groups;
  if (!goups) return false;
  return goups.includes(groupName);
}

export function GetIdentity() {
  const json = Cookies.get(user_key);
  if (!json) return null;
  var currentUser = JSON.parse(json);

  console.log(currentUser);
  return { UserId: currentUser.Id };
}

export function SetUser(user) {
  console.log("set user:" + JSON.stringify(user));
  Cookies.set(user_key, JSON.stringify(user));
}

export function Reset() {
  Cookies.remove(user_key);
}
