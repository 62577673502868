import Config from "../../../configurations/config";
import axios from "axios";

export function GetAll(request) {
  //const apiPayload = { identity: identity };
  return Exec("asokeywords/all", request);
}

export function Edit(identity, model) {
  const apiPayload = { identity: identity, model: model };
  return Exec("asokeywords/edit", apiPayload);
}

export function GetOne(identity, itemId) {
  const apiPayload = { identity: identity, ItemId: itemId };
  return Exec("asokeywords/one", apiPayload);
}

export function Delete(identity, item) {
  const apiPayload = { identity: identity, ItemId: item.Id };
  return Exec("asokeywords/delete", apiPayload);
}

export function GetWebPage(request) {
  return Exec("asokeywords/webpage", request);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
