import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetTickets } from "../Api/HelpDeskClient";

export default function useTicketList(projectId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetTickets(projectId).then((response) => {
      var data = response.data.filter((item) => !item.archived);
      setData(data);
      console.log(data);
      setLoading(false);
    });
  }, [projectId]);

  return { data, setData, loading, error };
}
