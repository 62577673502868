import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import logo from "../Assets/logo.png";
import { useMatch, useNavigate, useLocation } from "react-router-dom";

export default function ItemRow({ item, copy }) {
  const navigate = useNavigate();

  const url = `/paywalls/${item.id}`;
  return (
    <li key={item.id}>
      <div className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">
              <img
                src={logo}
                className="h-14 w-14 rounded"
                width={95}
                height={95}
              />
              {/*  <img className="h-14 w-14 rounded" src={job.Logo ?? "logo.png"} alt="" /> */}
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
              <div>
                <p className="truncate text-sm font-medium text-indigo-600">
                  {item.text ?? "Undefined"}
                </p>
                <p className="flex items-center text-sm text-gray-500">
                  <span className="mt-1 truncate">{"Pay Wall"}</span>
                </p>
                {/* <span className={style}>Date</span> */}
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={() => copy(item)}
              className="mr-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Copy
            </button>
          </div>

          <div>
            <button
              onClick={() => navigate(url)}
              className="mr-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            >
              Open
            </button>
          </div>

          <div>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </li>
  );
}
