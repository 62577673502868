import { useCallback, useState, useEffect } from "react";

import { GetIdentity } from "../../../components/Auth/AppState";
import { GetAll } from "./../Api/ASOClient";

export default function useUserList(project) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setData([]);

    // const identity = GetIdentity();
    const request = { ProjectId: project.Id };
    //===
    GetAll(request).then((response) => {
      console.log(response?.data);
      var items = response?.data ?? [];
      setData(items);
      setLoading(false);
    });
  }, [project.Id]);

  return { data, setData, loading, error };
}
