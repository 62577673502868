import { useForm } from "react-hook-form";
import useKeywordEdit from "./Hooks/useKeywordEdit";

import { useState } from "react";
import { Edit } from "./Api/ASOClient";
import { GetIdentity } from "../../components/Auth/AppState";

import useValidateASO from "./Hooks/useValidateASO";

export default function ASOKeywordScore({ item, setItem }) {
  const { validate, loading } = useValidateASO(item, setItem);
  const [saving, setSaving] = useState(false);

  const saveItem = async (item) => {
    setSaving(true);
    try {
      var identity = GetIdentity();
      await Edit(identity, item);
      alert("Data saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Error saving data.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="m-5">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-2">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                AI request
              </label>
              <div className="mt-2">{`You are ASO specialist. Analyze ASO keyword "${item?.Keyword}" with URL "${item?.Url}". Provide score and Insights Table with Action and Priority, Details.`}</div>
            </div>
          </div>
        </div>

        <button
          onClick={() => validate()}
          className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600"
        >
          {loading ? "Loading..." : "Get ASO Insights"}
        </button>

        <button
          onClick={() => saveItem(item)}
          className="ml-2 px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow hover:bg-green-600"
        >
          {saving ? "Saving..." : "Save"}
        </button>

        {item.Score && (
          <div className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded-md">
            <h3 className="text-lg font-semibold mb-2">ASO Score</h3>
            <p>{item.Score}</p>
          </div>
        )}

        {item.Insights && item.Insights.length > 0 && (
          <div className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded-md max-h-96 overflow-y-auto">
            <h3 className="text-lg font-semibold mb-2">ASO Insights</h3>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Priority
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Details
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Impact
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {item.Insights.map((insight, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {insight.Action}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {insight.Priority}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {insight.Details}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {insight.Impact}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
