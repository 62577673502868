import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { GetWebPage } from "../Api/ASOClient";

import { convert } from "html-to-text";

export default function useUserEdit(item, setItem) {
  const [loading, setLoading] = useState(false);

  const validate = useCallback(async () => {
    try {
      setLoading(true);

      var apikey =
        "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";

      var keyword = item?.Keyword;
      var url = item?.Url;

      const getResponse = await GetWebPage({ Url: url });
      const html = getResponse.data.Html;
      const htmlText = convert(html, {
        wordwrap: 500,
      });

      console.log(htmlText);
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",

          messages: [
            {
              role: "system",
              content:
                "You are an ASO expert. Optimize search results. JSON output.",
            },

            {
              role: "user",
              content: `${htmlText}`,
            },

            {
              role: "user",
              content: `ASO keyword: "${keyword}", Analyze html for provide Insights. Output "score" property from 0 to 100 and "insights" property as a table with columns: Action,	Priority,	Details, Impact, output only in json format score and table. Output only json.`,
            },
          ],
          max_tokens: 2000,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apikey}`,
          },
        }
      );

      var output = response.data.choices[0].message.content;
      output = output.replace(/^```json|```$/g, "").trim();
      console.log(output);
      const data = JSON.parse(output);

      setItem({ ...item, Score: data.score, Insights: data.insights });
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [item, setItem]);

  return { validate, loading };
}
