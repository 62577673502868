import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { useCallback, useState, useEffect } from "react";

import NotFoundView from "./Components/NotFoundView";
// ---
import ItemRow from "./Components/ItemRow";
import useKeywordList from "./Hooks/useKeywordList";
// ---
import Spinner from "../../components/Core/DottedSpinner/DottedSpinner";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import { Delete } from "./Api/ASOClient";
import { GetIdentity } from "../../components/Auth/AppState";

export default function ASOKeywordList({ project }) {
  const { data, setData, loading, error } = useKeywordList(project);

  const deleteItem = async (item) => {
    try {
      var identity = GetIdentity();
      await Delete(identity, item);

      setData((prevData) => prevData.filter((i) => i.Id !== item.Id));
    } catch (error) {
      console.error("Error deleting item:", error);
      alert("Error deleting item.");
    }
  };

  return (
    <div className="m-5 w-full">
      <a
        type="button"
        href={`/project/${project.Id}/asokeywords/edit`}
        className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        Add Keyword
      </a>

      {loading ? (
        <LoadingControl />
      ) : (
        <div className="overflow-hidden bg-white shadow">
          <ul role="list" className="divide-y divide-gray-200">
            {!data ? (
              <NotFoundView />
            ) : (
              data?.map((item) => (
                <ItemRow key={item.Id} deleteItem={deleteItem} item={item} />
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
