import React, { useState } from "react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import ASOKeywordScore from "../ASOKeywordScore";
import userLogo from "./../Assets/user.png";
// ----

export default function UserRow({ item, deleteItem }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(item);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <li key={currentItem.Id}>
        <a href={"#"} className="block hover:bg-gray-50" onClick={openPopup}>
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="flex-shrink-0">
                <img
                  src={userLogo}
                  className="h-14 w-14 rounded"
                  width={95}
                  height={95}
                />
                {/*  <img className="h-14 w-14 rounded" src={job.Logo ?? "logo.png"} alt="" /> */}
              </div>

              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                <div>
                  <p className="truncate text-sm font-medium text-indigo-600">
                    {currentItem.Keyword ?? "Undefined"}
                  </p>
                  <p className="flex items-center text-sm text-gray-500">
                    <span className="mt-1 truncate">
                      {currentItem.Url ?? "Undefined"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="pl-1"></div>

            <div class="pl-1"></div>

            <div class="flex-none mx-3">
              <div class="text-center text-sm">
                <div class="font-medium dark:text-white text-sm">Score</div>
                <div class="text-sm">{currentItem.Score ?? "Undefined"}</div>
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-center text-sm">
                <div class="font-medium dark:text-white text-sm">Insights</div>
                <div class="text-sm text-red-600">{55 ?? "Undefined"}</div>
              </div>
            </div>

            <div>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </a>
      </li>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-4xl overflow-y-auto max-h-screen">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">ASO Keyword Score</h2>
              <button
                onClick={closePopup}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>

            <ASOKeywordScore item={currentItem} setItem={setCurrentItem} />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={closePopup}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Are you sure you want to delete this item?")) {
                    deleteItem(currentItem);
                    closePopup();
                  }
                }}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
