import React, { useState } from "react";

const PlatformProductEditor = ({ product, setProduct, removeProduct }) => {
  /*const [product, setProduct] = useState({
    sku: "",
    price: "",
    originalPrice: "",
    defaultPrice: "",
    platform: "",
    type: "",
    offers: [],
  });*/

  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    var newProduct = { ...product, [name]: value };
    setProduct(newProduct);
    //setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleOffersChange = (index, field, value) => {
    const updatedOffers = [...product.offers];
    updatedOffers[index] = { ...updatedOffers[index], [field]: value };
    setProduct({ ...product, offers: updatedOffers });
  };

  const addOffer = () => {
    setProduct({
      ...product,
      offers: [
        ...product.offers,
        { text: "", description: "", color: "", background: "", type: "main" },
      ],
    });
  };

  const deleteOffer = (index) => {
    const updatedOffers = product.offers.filter((_, i) => i !== index);
    setProduct({ ...product, offers: updatedOffers });
  };

  const handleSave = () => {
    console.log("Product data saved:", product);
    setIsEditing(false);
    // Add save logic here (e.g., API call)
  };

  return (
    <div className="p-4">
      <div className="flex items-center space-x-4 mb-4">
        <div>
          <label className="block text-sm font-medium">SKU</label>
          <input
            type="text"
            name="sku"
            value={product.sku}
            onChange={handleChange}
            className="border rounded px-2 py-1 w-full"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Price</label>
          <input
            type="text"
            name="price"
            value={product.price}
            onChange={handleChange}
            className="border rounded px-2 py-1 w-full"
          />
        </div>

        <button
          onClick={() => setIsEditing(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Edit
        </button>
        <button
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            var c = confirm("Confirm to delete?");
            if (c) removeProduct();
          }}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Delete
        </button>
      </div>

      {isEditing && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-full max-w-2xl overflow-y-auto max-h-screen">
            <h2 className="text-xl font-bold mb-4">Edit Product</h2>

            <div className="mb-4">
              <label className="block text-sm font-medium">SKU</label>
              <input
                type="text"
                name="sku"
                value={product.sku}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">Price</label>
              <input
                type="text"
                name="price"
                value={product.price}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">
                Original Price
              </label>
              <input
                type="text"
                name="originalPrice"
                value={product.originalPrice}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">Default Price</label>
              <input
                type="text"
                name="defaultPrice"
                value={product.defaultPrice}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">Platform</label>
              <select
                name="platform"
                value={product.platform}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              >
                <option value="">Select Platform</option>
                <option value="ios">iOS</option>
                <option value="droid">Droid</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">Type</label>
              <select
                name="type"
                value={product.type}
                onChange={handleChange}
                className="border rounded px-2 py-1 w-full"
              >
                <option value="">Select Type</option>
                <option value="sub">Subscription</option>
                <option value="inapp">InApp Purchase</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium">Offers</label>
              <div className="grid grid-cols-2 gap-4 max-h-60 overflow-y-auto">
                {product.offers.map((offer, index) => (
                  <div key={index} className="p-2 border rounded relative">
                    <button
                      onClick={() => deleteOffer(index)}
                      className="absolute top-1 right-1 text-red-500 hover:text-red-700"
                    >
                      &times;
                    </button>
                    <input
                      type="text"
                      value={offer.text}
                      onChange={(e) =>
                        handleOffersChange(index, "text", e.target.value)
                      }
                      placeholder="Text"
                      className="border rounded px-2 py-1 mb-2 w-full"
                    />
                    <input
                      type="text"
                      value={offer.description}
                      onChange={(e) =>
                        handleOffersChange(index, "description", e.target.value)
                      }
                      placeholder="Description"
                      className="border rounded px-2 py-1 mb-2 w-full"
                    />
                    <input
                      type="color"
                      value={offer.color}
                      onChange={(e) =>
                        handleOffersChange(index, "color", e.target.value)
                      }
                      placeholder="Color"
                      className="border rounded px-2 py-1 mb-2 w-full"
                    />
                    <input
                      type="color"
                      value={offer.background}
                      onChange={(e) =>
                        handleOffersChange(index, "background", e.target.value)
                      }
                      placeholder="Background"
                      className="border rounded px-2 py-1 mb-2 w-full"
                    />
                    <select
                      value={offer.type}
                      onChange={(e) =>
                        handleOffersChange(index, "type", e.target.value)
                      }
                      className="border rounded px-2 py-1 w-full"
                    >
                      <option value="">None</option>
                      <option value="main">Main</option>
                      <option value="tag">Tag</option>
                      <option value="text">Text</option>
                      <option value="button">Button</option>
                    </select>
                  </div>
                ))}
              </div>
              <button
                onClick={addOffer}
                className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Add Offer
              </button>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformProductEditor;
