import * as React from "react";

import logo from "../Assets/helpdesk.png";

export default function ItemView({ ticket, onClick }) {
  return (
    <div class="block relative">
      <li key={ticket.id} class="flex flex-row" onClick={() => onClick(ticket)}>
        <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
          <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
            <img
              alt="profil"
              src={logo}
              class="mx-auto object-cover rounded-md h-14 w-14"
            />
          </div>

          <div class="flex-1 pl-1">
            <div class="font-medium dark:text-white text-sm">
              <div>
                {ticket.app.key}-{ticket.app.platform}
              </div>
              <div>{new Date(ticket.createdAt).toISOString()}</div>
            </div>
            <div class="text-sm">
              <div>{ticket.message}</div>
            </div>
          </div>

          <div class="pl-1"></div>

          <div class="pl-1"></div>
        </div>
      </li>
    </div>
  );
}
