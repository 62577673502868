import React, { useState } from "react";

const HeroSectionEditor = ({ hero, setHero }) => {
  /*
  const [heroSection, setHeroSection] = useState({
    logo: "",
    text: "",
    features: [{ text: "" }],
  });
  */

  const updateLogo = (e) => {
    setHero({ ...hero, logo: e.target.value });
  };

  const updateText = (e) => {
    setHero({ ...hero, text: e.target.value });
  };

  const updateFeature = (index, value) => {
    const updatedFeatures = [...hero.features];
    updatedFeatures[index].text = value;
    setHero({ ...hero, features: updatedFeatures });
  };

  const updateFeatureDescription = (index, value) => {
    const updatedFeatures = [...hero.features];
    updatedFeatures[index].description = value;
    setHero({ ...hero, features: updatedFeatures });
  };

  const addFeature = () => {
    setHero({
      ...hero,
      features: [...hero.features, { text: "", description: "" }],
    });
  };

  const removeFeature = (index) => {
    const updatedFeatures = hero.features.filter((_, i) => i !== index);
    setHero({ ...hero, features: updatedFeatures });
  };

  return (
    <div className="p-6 bg-gray-100">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-4">Hero Section</h1>

        {/* Logo Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="logo">
            Logo URL
          </label>
          <input
            type="text"
            id="logo"
            value={hero.logo}
            onChange={updateLogo}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter logo URL"
          />
        </div>

        {/* Hero Text Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1" htmlFor="text">
            Hero Text
          </label>
          <input
            type="text"
            id="text"
            value={hero.text}
            onChange={updateText}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter hero text"
          />
        </div>

        {/* Features List */}
        <div className="mb-4">
          <label className="block text-sm font-medium mb-2">Features</label>
          {hero.features.map((feature, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={feature.text}
                onChange={(e) => updateFeature(index, e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md mr-2"
                placeholder={`Feature ${index + 1}`}
              />
              <input
                type="text"
                value={feature.description || ""}
                onChange={(e) =>
                  updateFeatureDescription(index, e.target.value)
                }
                className="w-full p-2 border border-gray-300 rounded-md mr-2"
                placeholder={`Description ${index + 1}`}
              />
              <button
                onClick={() => removeFeature(index)}
                className="px-2 py-1 text-xs text-white bg-red-500 rounded-md hover:bg-red-600"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            onClick={addFeature}
            className="mt-2 px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Add Feature
          </button>
        </div>

        {/* Preview 
        <div className="mt-6">
          <h2 className="text-xl font-bold mb-2">Preview</h2>
          <div className="p-6 border border-gray-300 rounded-lg">
            {hero.logo && (
              <img
                src={hero.logo}
                alt="Logo"
                className="w-32 h-32 object-contain mb-4"
              />
            )}
            <h1 className="text-2xl font-bold mb-4">{hero.text}</h1>
            <ul className="list-disc pl-5">
              {hero.features.map((feature, index) => (
                <li key={index}>{feature.text}</li>
              ))}
            </ul>
          </div>
        </div>
        */}
      </div>
    </div>
  );
};

export default HeroSectionEditor;
