import React, { useState } from "react";

const GroupsControl = ({ groups, setGroups }) => {
  const [group, setGroup] = useState("");

  const addGroup = () => {
    if (group && !groups.includes(group)) {
      setGroups([...groups, group]);
      setGroup("");
    }
  };

  const removeGroup = (groupToRemove) => {
    setGroups(groups.filter((g) => g !== groupToRemove));
  };

  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Groups
      </label>
      <div className="mt-2 flex">
        <input
          type="text"
          value={group}
          onChange={(e) => setGroup(e.target.value)}
          placeholder="Add a group"
          className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <button
          type="button"
          onClick={addGroup}
          className="ml-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600"
        >
          Add
        </button>
      </div>
      <ul className="mt-2">
        {groups.map((g, index) => (
          <li key={index} className="flex justify-between items-center">
            <span>{g}</span>
            <button
              type="button"
              onClick={() => removeGroup(g)}
              className="text-red-500 hover:text-red-700"
            >
              &times;
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupsControl;
