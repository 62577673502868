import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import userLogo from "./../Assets/user.png";
import GroupsControl from "./GroupsControl";
import { UserEdit } from "../Api/UserClient";
import { GetIdentity } from "../../../components/Auth/AppState";

export default function UserRow({ item }) {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(item);
  const [groups, setGroups] = useState(item.Groups || []);

  const openEdit = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const saveUser = async () => {
    try {
      var identity = GetIdentity();
      await UserEdit(identity, { ...currentItem, Groups: groups });
      alert("User saved successfully!");
      closePopup();
    } catch (error) {
      console.error("Error saving user:", error);
      alert("Error saving user.");
    }
  };

  return (
    <>
      <li key={item.Id}>
        <a href={"#"} className="block hover:bg-gray-50" onClick={openEdit}>
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="flex-shrink-0">
                <img
                  src={userLogo}
                  className="h-14 w-14 rounded"
                  width={95}
                  height={95}
                />
              </div>
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-2">
                <div>
                  <p className="truncate text-sm font-medium text-indigo-600">
                    {item.Name ?? "Undefined"}
                  </p>
                  <p className="flex items-center text-sm text-gray-500">
                    <span className="mt-1 truncate">
                      {item.Email ?? "Undefined"}
                    </span>
                  </p>
                  <p className="flex items-center text-sm text-gray-500">
                    <span className="mt-1 truncate">
                      {item.Groups?.join(", ") ?? "No Groups"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </a>
      </li>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-full max-w-2xl overflow-y-auto max-h-screen shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Edit User</h2>
              <button
                onClick={closePopup}
                className="text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <input
                type="text"
                value={currentItem.Name}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, Name: e.target.value })
                }
                className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <input
                type="email"
                value={currentItem.Email}
                onChange={(e) =>
                  setCurrentItem({ ...currentItem, Email: e.target.value })
                }
                className="block w-full p-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <GroupsControl groups={groups} setGroups={setGroups} />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={closePopup}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={saveUser}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
