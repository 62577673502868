import axios from "axios";

export function GetTickets(projectId) {
  const apiPayload = { ProjectId: projectId, Status: 0 };
  return Exec("helpdesk/all", apiPayload);
}

export function EditTicket(ticket) {
  const apiPayload = { Model: ticket };
  return Exec("helpdesk/edit", apiPayload);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));

  var server =
    "https://pekh32fwsawrtsprn4tnhzn6ei0vcdll.lambda-url.us-west-2.on.aws/";
  return axios.post(server, requestBody);
}
