import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";

import { GetIdentity } from "../../../components/Auth/AppState";
import { Edit } from "../Api/ASOClient";
import { useNavigate } from "react-router-dom";

export default function useUserEdit(formValues, project) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const itemEdit = useCallback(async () => {
    try {
      setLoading(true);

      var identity = GetIdentity();
      var model = {
        ProjectId: project.Id,
        Keyword: formValues.keyword,
        Url: formValues.url,
        Score: "0",
      };

      const response = await Edit(identity, model);
      const operationResponse = response.data;
      if (!operationResponse.Success) {
        toast.error(operationResponse.Message);
        return;
      }
      toast.success("Saved");

      navigate(`/project/${project.Id}/asokeywords`);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [formValues, navigate, project.Id]);

  return { itemEdit, loading };
}
